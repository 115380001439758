import { useContext, useEffect, useState } from "react";
import { FormContainer } from "react-hook-form-mui";
import { Navigate, useLocation, useNavigate } from "react-router-dom";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Avatar,
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";
import { red } from "@mui/material/colors";

import * as amplitude from "@amplitude/analytics-browser";

import { PermissionsContext } from "../PermissionsContext";
import axios from "../axios";
import { ButtonWithText } from "../components/ButtonWithText";
import APIKeyLogin from "../components/loginpages/APIKeyLogin";
import AscendLogin from "../components/loginpages/Ascend";
import ClientIDClientSecretLogin from "../components/loginpages/ClientIDClientSecretLogin";
import DriverTechLogin from "../components/loginpages/DriverTech";
import EzLogzLogin from "../components/loginpages/EzLogz";
import FleetCompleteLogin from "../components/loginpages/FleetComplete";
import FleetUpLogin from "../components/loginpages/FleetUp";
import GeotabLogin from "../components/loginpages/Geotab";
import HOSConnectLogin from "../components/loginpages/HOSConnect";
import IsaacLogin from "../components/loginpages/Isaac";
import LytxLogin from "../components/loginpages/Lytx";
import SelectiveDrive from "../components/loginpages/SelectiveDrive";
import SwitchboardLogin from "../components/loginpages/Switchboard";
import TelogisLogin from "../components/loginpages/Telogis";
import TraccarLogin from "../components/loginpages/Traccar";
import TruckXLogin from "../components/loginpages/TruckX";
import TTLogin from "../components/loginpages/TT";
import UserPassLogin from "../components/loginpages/UserPass";
import VerizonLogin from "../components/loginpages/Verizon";
import WebfleetLogin from "../components/loginpages/Webfleet";
import ZonarLogin from "../components/loginpages/Zonar";
import { getTSPHelpTextByKey, getTSPNameByKey } from "../tsps";
import { LoginFormData } from "../types/LoginFormData";
import { checkValid } from "../utils/checkValid";
import { motiveRedirectURI } from "../utils/redirect";
import { ButtonExitLink, LinkPage } from "../components/ButtonExitLink";

const helpModalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "1px solid #D3D3D3",
  boxShadow: 24,
  p: 4,
  borderRadius: 1,
};

export default function Onboard() {
  const navigate = useNavigate();
  const { search } = useLocation();

  const [disableButton, setDisableButton] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [helpModalOpen, setHelpModalOpen] = useState(false);

  const {
    approved,
    fleetCode,
    magicLink,
    motiveClientID,
    samsaraClientID,
    zubieClientID,
    serviceID,
    token,
    TSP,
    updatingTSP,
    serviceName,
    redirectURIError,
    redirectURISuccess,
    setOnboarded,
    setRedirectURI,
    setTSP,
  } = useContext(PermissionsContext);

  useEffect(() => {
    if (!checkValid(token)) {
      navigate("/error", {
        state: {
          errorMsg: "Onboarding link is invalid.",
          errorCaption: "Please try to onboard again.",
        },
      });
    }

    if (!approved) {
      navigate({
        pathname: "/",
        search: search,
      });
    }
  });

  const getTSPLogin = (TSP: string) => {
    switch (TSP) {
      case "apollo":
      case "blueinktech":
      case "bigroad":
      case "budgetgps":
      case "eldmandate":
      case "eroad":
      case "fleethunt":
      case "mapon":
      case "matrack":
      case "maven":
      case "onestep":
      case "optima":
      case "swift":
        return <APIKeyLogin />;
      case "hcss":
      case "netradyne":
      case "tive":
      case "truckspy":
      case "platformscience":
        return <ClientIDClientSecretLogin />;
      case "ascend":
        return <AscendLogin />;
      case "drivertech":
        return <DriverTechLogin />;
      case "ezlogz":
        return <EzLogzLogin />;
      case "fleetcomplete":
        return <FleetCompleteLogin />;
      case "fleetup":
        return <FleetUpLogin />;
      case "geotab":
        return <GeotabLogin />;
      case "hosconnect":
        return <HOSConnectLogin />;
      case "isaac":
        return <IsaacLogin />;
      case "lytx":
        return <LytxLogin />;
      case "selectivedrive":
        return <SelectiveDrive />;
      case "switchboard":
        return <SwitchboardLogin />;
      case "telogis":
        return <TelogisLogin />;
      case "traccar":
        return <TraccarLogin />;
      case "truckx":
        return <TruckXLogin />;
      case "tt":
        return <TTLogin />;
      case "verizon":
        return <VerizonLogin />;
      case "webfleet":
        return <WebfleetLogin />;
      case "zonar":
        return <ZonarLogin />;
      default:
        if (getTSPNameByKey(TSP) == null) {
          return (
            <Navigate
              to="/error"
              state={{
                errorMsg: "Onboarding link is invalid.",
                errorCaption: "Please try to onboard again.",
              }}
            />
          );
        }
        return <UserPassLogin />;
    }
  };

  // For Samsara/Motive, Link first redirects to the TSP to let the user authorize an app.
  // After approval, Samsara/Motive will redirect back to Link - we are allowed a
  // single query parameter to encode details of the original request.
  const linkState = `${token},${updatingTSP},${magicLink},${serviceID},${fleetCode}`;

  useEffect(() => {
    switch (TSP) {
      case "motive":
        if (motiveClientID) {
          if (serviceName.includes("AtoB")) {
            window.location.replace(
              `https://api.gomotive.com/oauth/authorize?client_id=${motiveClientID}&response_type=code&scope=companies.read+users.read+hos_logs.available_time+hos_logs.hours_of_service+hos_logs.hos_violation+hos_logs.logs+vehicles.read+locations.vehicle_locations_list+locations.vehicle_locations_single+locations.driver_locations+assets.read+locations.asset_locations+fuel_purchases.manage&redirect_uri=${motiveRedirectURI(
                linkState,
              )}`,
            );
          } else if (serviceName.includes("Revenova")) {
            window.location.replace(
              `https://api.gomotive.com/oauth/authorize?client_id=${motiveClientID}&response_type=code&scope=companies.read+users.manage+hos_logs.available_time+hos_logs.hours_of_service+hos_logs.hos_violation+hos_logs.logs+vehicles.manage+locations.vehicle_locations_list+locations.vehicle_locations_single+locations.driver_locations+assets.manage+locations.asset_locations+documents.manage+messages.manage&redirect_uri=${motiveRedirectURI(
                linkState,
              )}`,
            );
          } else {
            window.location.replace(
              `https://api.gomotive.com/oauth/authorize?client_id=${motiveClientID}&response_type=code&scope=companies.read+users.read+hos_logs.available_time+hos_logs.hours_of_service+hos_logs.hos_violation+hos_logs.logs+vehicles.read+locations.vehicle_locations_list+locations.vehicle_locations_single+locations.driver_locations+assets.read+locations.asset_locations&redirect_uri=${motiveRedirectURI(
                linkState,
              )}`,
            );
          }
        } else {
          setShowError(true);
          setErrorText(
            "No Motive app is associated with this account - please contact Axle support",
          );
        }

        break;

      case "samsara":
        if (samsaraClientID)
          window.location.replace(
            `https://api.samsara.com/oauth2/authorize?client_id=${samsaraClientID}&response_type=code&state=${linkState}`,
          );
        else {
          setShowError(true);
          setErrorText(
            "No Samsara app is associated with this account - please contact Axle support",
          );
        }
        break;

      case "zubie":
        if (zubieClientID)
          window.location.replace(
            `https://login.zubiecar.com/authorize?client_id=${zubieClientID}&response_type=code&state=${linkState}`,
          );
        else {
          setShowError(true);
          setErrorText(
            "No Zubie app is associated with this account - please contact Axle support",
          );
        }
        break;

      case "":
        navigate("/error", {
          state: {
            errorMsg: "Onboarding link is invalid.",
            errorCaption: "Please try to onboard again.",
          },
        });
        break;

      default:
        break;
    }
  }, [
    linkState,
    motiveClientID,
    navigate,
    samsaraClientID,
    zubieClientID,
    TSP,
    serviceName,
    redirectURISuccess,
  ]);

  const handleFormSubmit = (data: LoginFormData) => {
    // disable login button
    setDisableButton(true);

    axios
      .post(
        "/service/onboard",
        {
          Name: fleetCode,
          CarrierName: data.carriername,
          DOTNumber: data.dotnumber,
          Username: data.username,
          Password: data.password,
          Database: data.database,
          Apikey: data.apikey,
          AuthorizationCode: data.authorizationcode,
          TSP: TSP,
          FTPUsername: data.FTPUsername,
          FTPPassword: data.FTPPassword,
          UpdateTSPConfirmed: updatingTSP ? true : false,
        },
        {
          headers: { Authorization: token ? "Bearer " + token : "" },
        },
      )
      .then((res) => {
        if (res.status === 201) {
          setOnboarded(true);
          // Success-specific URI should override default RedirectURI when it exists
          if (redirectURISuccess) {
            setRedirectURI(redirectURISuccess);
          } else if (magicLink) {
            setRedirectURI(process.env.REACT_APP_DEV_DASH);
          } else {
            setRedirectURI(res.data["RedirectURI"]);
          }
          navigate("/confirmation");
        }
      })
      .catch((err) => {
        setShowError(true);
        if (err.response) {
          setErrorText(err.response.data);
        } else {
          setErrorText("Unknown error occurred.");
        }
        setDisableButton(false);
      });
  };

  const TSPName = getTSPNameByKey(TSP);
  const TSPHelpText = getTSPHelpTextByKey(TSP);

  if (
    (TSP === "motive" && motiveClientID) ||
    (TSP === "samsara" && samsaraClientID)
  )
    return (
      <>
        <p>Redirecting...</p>
        <CircularProgress sx={{ color: red[500] }} />
      </>
    );
  else
    return (
      <FormContainer
        defaultValues={{
          username: "",
          password: "",
          database: "",
          apikey: "",
          authorizationcode: "",
          carriername: "",
          FTPUsername: "",
          FTPPassword: "",
          dotnumber: 0,
        }}
        onSuccess={handleFormSubmit}
        FormProps={{
          autoComplete: "off",
        }}
      >
        <Grid
          container
          sx={{
            "& .MuiTextField-root": { width: "100%" },
            height: "70vh",
          }}
          direction="column"
          justifyContent="center"
          alignItems="center"
          maxWidth={"100%"}
          display="block"
          overflow={"scroll"}
        >
          <Grid
            item
            container
            direction={"row"}
            alignItems="center"
            maxWidth={"100%"}
          >
            <Grid item xs={4}>
              <Button
                sx={{
                  minWidth: "40px",
                  color: red[500],
                  "&:hover": {
                    backgroundColor: red[100],
                  },
                }}
                onClick={() => {
                  setTSP(null);
                  navigate(`/selectTSP?token=${token}`);
                }}
              >
                <ArrowBackIcon />
              </Button>
            </Grid>
            <Grid
              container
              item
              xs={4}
              mb={2}
              direction={"row"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Grid item>
                <Avatar
                  src={`/images/${TSPName}.png`}
                  variant="square"
                  sx={{ width: 100, height: 100 }}
                />
              </Grid>
            </Grid>
            {redirectURIError ? (
              <Grid item xs={4} display={"flex"} justifyContent={"right"}>
                <ButtonExitLink
                  token={token}
                  redirectURIError={redirectURIError}
                  serviceName={serviceName}
                  pageOnExit={LinkPage.OnboardingCreds}
                  selectedTSP={TSP}
                />
              </Grid>
            ) : null}
          </Grid>
          <Grid item mb={1}>
            <Typography variant="h5" align="center">
              Log into your {TSPName} account
            </Typography>
            <Typography variant="body2" align="center">
              By providing your {TSPName} credentials to Axle, you're enabling
              Axle to retrieve your telematics data.
            </Typography>
          </Grid>
          <Grid item width="100%" mt={1}>
            {showError ? <Alert severity={"error"}>{errorText}</Alert> : null}
          </Grid>
          {getTSPLogin(TSP)}
          <Grid item width="100%">
            <ButtonWithText
              type="submit"
              disabled={disableButton}
              onClick={() => {
                amplitude.track("User Selected Login", {
                  tsp: TSP,
                  serviceName: serviceName,
                });
              }}
            >
              {disableButton ? (
                <CircularProgress sx={{ color: "white" }} />
              ) : (
                "Login"
              )}
            </ButtonWithText>
          </Grid>
          <Grid item width="100%">
            <Button
              sx={{
                color: "black",
                margin: "0 auto",
                display: "flex",
                width: "100%",
                textTransform: "none",
              }}
              onClick={() => setHelpModalOpen(true)}
            >
              Need help finding your credentials?
            </Button>
          </Grid>
          <Modal
            open={helpModalOpen}
            onClose={() => setHelpModalOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={helpModalStyle}>
              <IconButton
                aria-label="close"
                sx={{ position: "fixed", top: 0, right: 0, zIndex: 2000 }}
                onClick={() => setHelpModalOpen(false)}
              >
                <CloseIcon />
              </IconButton>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Need help finding your credentials?
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                {TSPHelpText}
              </Typography>
            </Box>
          </Modal>
        </Grid>
      </FormContainer>
    );
}
